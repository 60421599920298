module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Villa Stanisi","short_name":"Villa Stanisi","start_url":"/en","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11f07e8b956cfa1abfbf4c8d661f7307"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","generateDefaultLanguagePage":true,"redirect":false,"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true},{"matchPath":"/:lang?/tips/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/info/good-to-know/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/info/instructions/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/info/devices/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/guestbook/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/house","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/house/(.*)","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/static/","getLanguageFromPath":false,"excludeLanguages":["en","de"]},{"matchPath":"/assets/","getLanguageFromPath":false,"excludeLanguages":["en","de"]},{"matchPath":"/admin","getLanguageFromPath":false,"excludeLanguages":["en","de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
